import { render, staticRenderFns } from "./wechatVideo.vue?vue&type=template&id=4422ca3b&scoped=true&"
import script from "./wechatVideo.vue?vue&type=script&lang=ts&"
export * from "./wechatVideo.vue?vue&type=script&lang=ts&"
import style0 from "./wechatVideo.vue?vue&type=style&index=0&id=4422ca3b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4422ca3b",
  null
  
)

export default component.exports