




























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import openDialog from '@/components/wechatEditor/openDialog.vue';
import videoDialog from '@/components/wechatEditor/videoDialog.vue';
import { materialApi } from '@/services/material-service';
@Component({
  components: {
    openDialog,
    videoDialog
  }
})
export default class wechatVideo extends Vue {
  public formImage: any = {};
  public form: any = {
    pages: 1,
    size: 10,
    current: 1,
    total: 0
  };
  public dialogVisible: Boolean = false;
  public loading: Boolean = true;
  private tableDataArr: any[] = [];
  public tableDataArrLen: Boolean = true;
  private currentRow: any = null;
  @Inject(materialApi) private materialApi!: materialApi;

  public search() {

  }
  addVideo(): void {
    // console.log(this.$refs.childDialog) //返回的是一个vue对象，所以可以直接调用其方法
    // (this.$refs.childDialog as openDialog).upVideo();
    this.dialogVisible = true;
  }
  //得到子组件传过来的值
  public getChild(val: any): void {
    var that = this;
    if (val) {
      that.dialogVisible = false;
      that.getList();
    }
  }
  public async created(): Promise<void> {
    await this.getList();
  }
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    this.form.type = 2;
    const res = await this.materialApi.getList(this.form);
    this.loading = false;
    let data: any = res.records
    this.formData(data)
    this.form.total = res.total;
    this.form.pages = res.pages;
    this.form.size = res.size;
    this.form.current = res.current;
  }
  public async formData(res: any) {
    let data = res, that = this;
    for (var i = 0; i < data.length; i++) {
      // data[i].
      let temp = data[i].createTime.split('T')   //split()方法，以T字符为截断，划分成2块，返回一个数组
      const year = temp[0].slice(0, 4)  //slice()截取字符，开始--结束
      const month = temp[0].slice(5, 7)
      const day = temp[0].slice(8, 10)
      const time_ = temp[1].slice(0, 5)
      data[i].createTime = `${year}-${month}-${day} ${time_}`

    }
    that.tableDataArr = data;
    if (that.tableDataArr.length <= 0) {
      that.tableDataArrLen = true;
    } else {
      that.tableDataArrLen = false;
    }
  }
  //删除
  private async handDelete(row: any): Promise<void> {
    console.log(row)
    let param = {
      id: row.id
    }
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.materialApi.delePic(param);
      this.getList()
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  public btnOK(): void {

  }
  private mounted() {

  }
}
